.download-button .payment-method-btn {
  margin-top: 343px;
}
.download-button .payment-method-btn .color {
  color: black;
  margin-left: 162px;
}
.download-button .payment-method-btn .save-payment-method-button {
  width: 207px;
  margin-left: 69px;
  font-size: 17px;
  background: #fddb6e;
  border-radius: 10px;
}
.download-button .payment-method-btn .new-payment-method-button {
  width: 207px;
  margin-left: 69px;
  font-size: 17px;
  border-radius: 10px;
  background: #f8d9e6;
}
