.section-styles {
  border: 1px solid #818286;
  margin-bottom: 16px;
  padding: 16px;
}
.section-styles .title-summary {
  color: #818286;
  display: block;
}
.section-styles .option-summary {
  color: #818286;
  display: block;
  margin-top: 12px;
}
.section-styles .note {
  color: #818385;
  font-weight: bolder;
}
.section-styles .ui.bulleted.list {
  margin-left: 16px;
}
.section-styles .ui.bulleted.list .item {
  margin-bottom: 16px;
  color: #818286;
}
.section-styles .ui.bulleted.list .item:last-child {
  margin-bottom: 0px;
}
.section-styles .btn {
  margin: 30px;
}
.section-styles .btn .primary-button.ui.button {
  background: #f8d9e6;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.section-styles .btn .primary-button.ui.button:hover {
  background: #43c1f3;
}
.section-styles .gold-button.ui.button {
  background: #fddb6e;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.section-styles .gold-button.ui.button:hover {
  background: #43c1f3;
}
.section-styles .content-quote {
  color: #43c1f3;
  font-size: 22px;
  font-weight: bold;
  display: block;
}
.section-styles .content-details {
  color: #818385;
}
