.label-styles {
  font-size: 12pt !important;
  opacity: 0.6;
  color: black;
  font-weight: normal;
}

.input input {
  width: 100% !important;
}

.quota-exceed-buttons input {
  color: #000;
  padding: 16px;
  background: #fddb6e;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  border-color: transparent;
  font-size: 18px;
  width: 95%;
  font-weight: 600;
}

.quota-exceed-buttons input:hover {
  cursor: pointer;
  background: #52c1f3;
}

.ui.checkbox input.hidden + label {
  font-size: 12pt !important;
  opacity: 0.6 !important;
  color: black !important;
  font-weight: normal !important;
}

.ui.form.dropdown-form {
  border: 1px solid #b3b3bf;
  border-radius: 5px;
  width: 235px;
  margin-left: 32px;
  margin-top: 12px;
}
.ui.form.dropdown-form label {
  font-size: 14px;
}

.ui.form .field.radio-fields {
  height: 50px;
  padding: 14px;
}
.ui.form .field.radio-fields:hover {
  background: #d4effa;
}
.ui.form .field.radio-fields label {
  font-size: 14px !important;
}

.ui.dropdown {
  width: 100% !important;
  color: #000;
  padding: 10px;
}
.ui.dropdown .text {
  opacity: 0.6;
}

.ui.dropdown > .dropdown.icon {
  margin-left: 5px !important;
  float: right;
  top: 5px;
}

.ui.dropdown .menu > * {
  width: 100%;
}

.ui.radio.checkbox .box,
.ui.radio.checkbox label {
  padding-left: 1.85714em;
  font-size: 18px;
  font-weight: bolder;
  opacity: 0.5;
}

.quota-exceed-buttons h3 {
  color: black;
  text-align: center;
  margin-top: 55px;
}
