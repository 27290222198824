.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  font-weight: bolder;
  position: relative;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}

.max_buildable_square_footage .heading-text {
  font-size: 28px;
  font-weight: normal;
}

.buildable_square_footage_by_far .heading-text {
  font-size: 28px;
  font-weight: normal;
}

.buildable_square_footage_by_height_limit .heading-text {
  font-size: 28px;
  font-weight: normal;
}

.difference_between_maximum_buildable_and_existing .heading-text {
  font-size: 28px;
  font-weight: normal;
}
