.purchase-info .download-report {
  padding: 0 16px;
}
.purchase-info .download-report .download-card {
  background-color: white;
  color: black;
  border-radius: 12px;
}
.purchase-info .download-report .download-card .download-header {
  border-bottom: 1px solid #f2f2f2;
}
.purchase-info .download-report .download-card .download-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
}
.purchase-info .download-report .download-card .download-body p {
  padding: 15px 18px;
}
.purchase-info .download-btns {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 17px;
}
.purchase-info .download-btns .btn-moveto-login {
  display: flex;
}
.purchase-info .download-btns .btn-moveto-login .button {
  display: flex !important;
  background: #ffd600 !important;
  border-radius: 12px !important;
  height: 60px;
  width: 175px;
}
.purchase-info .download-btns .btn-moveto-login span {
  font-size: 10px;
  display: inline-block;
  margin-left: 10px;
  color: black;
  text-transform: uppercase;
  text-align: justify;
  line-height: 15px;
  font-weight: bold;
}
.purchase-info .download-btns .btn-guest {
  display: flex;
}
.purchase-info .download-btns .btn-guest .button {
  display: flex !important;
  background: rgba(255, 255, 255, 0.35) !important;
  border-radius: 12px !important;
  height: 60px;
  width: 175px;
}
.purchase-info .download-btns .btn-guest span {
  font-size: 11px;
  display: inline-block;
  margin-left: 10px;
  color: #69beee;
  text-transform: uppercase;
  text-align: justify;
  line-height: 15px;
  font-weight: 400;
}
.purchase-info .save-payment-method-button.ui.button {
  background: #fddb6e;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.purchase-info .save-payment-method-button.ui.button:hover {
  background: #43c1f3;
}
.purchase-info .payment-button.ui.button {
  background: #f8d9e6;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.purchase-info .payment-button.ui.button:hover {
  background: #43c1f3;
}
.purchase-info .grayed-text {
  color: #818286;
  font-size: x-large;
  text-align: center;
  margin: 16px 0;
}
