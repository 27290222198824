.use-filter {
  color: #000;
}
.use-filter .use-sub-title {
  margin-left: 58px;
}
.use-filter .use-fields {
  border-top: 1px solid #f2f2f2;
  padding: 17px 20px 17px 42px;
  margin-bottom: 16px;
}
.use-filter .horizontal-row {
  margin-top: 25px;
  margin-bottom: 25px;
}
