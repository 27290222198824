.contact-us {
  margin-bottom: 20px;
}
.contact-us h1 {
  font-family: "Inter-Black";
}
.contact-us .contact-form {
  background: #ffffff;
  border-radius: 12px;
}
.contact-us .contact-form h3 {
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 15px 7px;
  font-family: "Inter-Bold";
}
.contact-us .contact-form .form-body {
  padding: 10px 10px 7px;
}
.contact-us p,
.contact-us a {
  color: #000;
  font-size: 18px;
  opacity: 0.7;
}
.contact-us a {
  opacity: 1;
}
.contact-us .ui.form .field {
  margin: 0 0 0.5em;
}
.contact-us .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
}
.contact-us .ui.form .field > label {
  color: #333333 !important;
  padding-left: 10px;
  margin-top: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.contact-us .ui.form .field.error .input,
.contact-us textarea {
  border: 1px solid #f2f2f2;
}
.contact-us textarea {
  border: 1px solid #f2f2f2;
}
.contact-us .contact-message .button {
  width: 45%;
  background: #69beee;
  border-radius: 5px;
  display: flex;
}
.contact-us .contact-message span {
  margin-top: 5px;
  margin-left: 10px;
  color: #333333;
}

@media only screen and (max-width: 500px) {
  .contact-us .contact-form h3 {
    font-size: 16px;
  }
}
