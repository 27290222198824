.data-header .header-display {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
}

.section .heading-text {
  font-weight: normal;
  margin-left: 5px;
}

.section .sub-heading {
  margin-bottom: 12px;
}

.li-text {
  color: #818286;
}

.section .section-data {
  margin-left: 30px;
  margin-top: 7px;
  display: flex;
}

.section .section-data .undefined {
  margin-bottom: 20px;
}

.section .section-header {
  display: flex;
}

.section .small-heading-class {
  margin-right: 8px;
  color: black;
  min-width: max-content;
  opacity: 0.7;
}

.section .small-heading-class-desc {
  color: black;
  min-width: max-content;
  opacity: 0.7;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-right: 18px;
}

.plan_districts_b {
  margin-top: -8px !important;
}

.plan_districts_b .single {
  margin-top: 29px !important;
}

.pattern_area_a {
  margin-bottom: 18px;
}

.far_base_b .heading-text {
  margin-bottom: 17px;
  width: auto;
}

.overlays_a hr {
  margin-top: 18px;
}

.overlays_a {
  margin-top: 18px !important;
}

.plan_districts_a {
  margin-top: 18px !important;
}

.pattern_area_a {
  margin-top: 18px !important;
}

.plan_districts_a hr {
  margin-top: 18px;
}

.far_base_c .heading-text {
  margin-top: -7px;
}
.far_base_c .section-description p {
  position: relative;
  transform: translateY(-26%);
}

.height_limit_base_c .heading-text {
  margin-top: 18px;
}
.height_limit_base_c .section-description p {
  position: relative;
  transform: translateY(70%);
}

.height_limit_base_c hr {
  margin-top: 18px !important;
}

.height_limit_base_b .heading-text {
  width: auto;
}

.height_limit_base_b hr {
  margin-top: 18px !important;
}

.far_base_a hr {
  margin-top: 18px !important;
}

.far_base_b hr {
  margin-top: 18px !important;
}

.far_base_c hr {
  margin-top: 18px !important;
}

.lot_type_b hr {
  margin-top: 18px !important;
}

.lot_type_c hr {
  margin-bottom: 18px !important;
}

.coverage_land_detail_a hr {
  margin-top: 18px !important;
}

.coverage_land_detail_b hr {
  margin-top: 18px !important;
}

.setbacks_h .heading-text {
  width: 216px;
}

.far_adjusted_a .heading-text {
  width: 225px;
}

.far_bonus_h .heading-text {
  width: 235px;
}

.far_bonus_a hr {
  margin-top: 18px !important;
}

.unit_minimum_c .sub-heading {
  width: 235px;
}

.unit_minimum_c hr {
  margin-top: 18px !important;
}

.unit_minimum_b hr {
  margin-top: 18px !important;
}

.height_limit_b hr {
  margin-top: 18px !important;
}

.development_comment_a .heading-text {
  width: 235px;
}

.development a {
  width: 235px;
}

.overlays_c hr {
  margin-top: 18px;
}

.overlays_c li {
  margin-top: -18px;
}

.summary .overlays_a li {
  font-weight: normal;
}

.summary .overlays_b li {
  font-weight: normal;
}

.summary .overlays_c li {
  font-weight: normal;
}

.height_limit_adjustment_a .heading-text {
  width: 225px;
}

.unit_maximum_a .heading-text {
  width: 225px;
}

.unit_maximum_b .heading-text {
  width: 225px;
}

.unit_maximum_c .heading-text {
  width: 225px;
}

.unit_maximum_d .heading-text {
  width: 225px;
}

.unit_bonus_maximum_d .heading-text {
  width: 225px;
}

.unit_bonus_maximum_l .heading-text {
  width: 225px;
}

.height_limit_adjustment_c .heading-text {
  width: 225px;
}

.height_limit_bonus_d .heading-text {
  width: 225px;
}

.height_limit_bonus_f .heading-text {
  width: 225px;
}

.height-limit .property-main-more {
  margin-bottom: 18px;
}

.height_limit_bonus_h .heading-text {
  width: 225px;
}

.height_limit_bonus_b .heading-text {
  width: 225px;
}

.unit_bonus_maximum_a hr {
  margin-top: 18px !important;
}

.unit_maximum_d_line {
  margin-top: -18px !important;
}

.height_limit_adjustment_line hr {
  margin-top: 18px !important;
}

.height_limit_bonus_a hr {
  margin-top: 18px !important;
}

.height_limit_bonus_a {
  width: 206px;
}

.unit_maximum_null h2 {
  font-size: 16px !important;
}

.height_limit_adjustment_null h2 {
  font-size: 16px !important;
}

.far_adjusted_null h2 {
  font-size: 16px !important;
}

.overlays_null h2 {
  font-size: 16px !important;
}

.link-image {
  margin-bottom: 18px;
  width: 50%;
  width: 172px !important;
  overflow: hidden;
  white-space: nowrap;
  background: #fafcfc;
}

.zone-detail .property-main-more {
  margin-bottom: 18px;
}

.residential-unit .property-main-more {
  margin-bottom: 18px;
}

.existing-structure .property-main-more {
  margin-bottom: 18px;
}

.land-detail .property-main-more {
  margin-bottom: 18px;
}

.height-limit .property-main-more {
  margin-bottom: 18px;
}
