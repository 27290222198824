/* boilerplate css */
html {
  position: relative;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Inter-Regular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden !important;
}

@font-face {
  font-family: "Inter-Regular";
  src:
    local("Inter"),
    url("../fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Black";
  src:
    local("Inter"),
    url("../fonts/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src:
    local("Inter"),
    url("../fonts/Inter-Bold.ttf") format("truetype");
}
.ui.primary.button {
  background-color: #43c1f3 !important;
}

code {
  font-family: "Inter" !important;
}

body ::-webkit-scrollbar {
  width: 5px !important;
}

/* general layout css */
#urbanform-styles .menu-columns > div {
  vertical-align: top;
  display: inline-block;
}
#urbanform-styles .menu-columns > div.thin-column {
  width: 85px;
  background: #43c1f3;
  height: 100vh;
  overflow-y: auto;
}
#urbanform-styles .menu-columns > div.thick-column {
  width: calc(100% - 85px);
}

.main-columns {
  display: flex;
}

.main-columns > div {
  vertical-align: top;
  display: inline-block;
}

/* left menu css */
#urbanform-styles .menu-columns > div.thin-column .menu {
  width: auto;
  background: none;
  box-shadow: none;
  border: none;
}
#urbanform-styles .menu-columns > div.thin-column .menu .icon-items {
  margin-top: 20px;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .border-management {
  display: flex;
  display: flex;
  flex-direction: row;
  height: 10px;
  background: #43c1f3;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .border-management
  .helper-border {
  background: #43c1f3;
  height: 10px;
  width: 50%;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .border-management
  .helper-border-top {
  background: #43c1f3;
  height: 10px;
  width: 50%;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .border-management
  .helper-border-second {
  background: #43c1f3;
  width: 50%;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .border-management.active {
  background-color: #bce6fb;
}
#urbanform-styles .menu-columns > div.thin-column .menu .icon-items .icon-item {
  color: #a4a2b6;
  line-height: 1.2rem;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .icon-item.active {
  background-color: white;
  border-radius: 10px;
  height: 70px;
  width: 63px;
  margin-left: 11px;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .icon-item.active
  span {
  color: black;
  font-weight: 400;
  font-family: "Inter-Regular";
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .icon-item
  .image {
  height: 35px;
  margin-bottom: 3px;
  margin: 0 auto;
}
#urbanform-styles
  .menu-columns
  > div.thin-column
  .menu
  .icon-items
  .icon-item
  span {
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  margin: 7px auto;
  text-transform: uppercase;
  font-family: "Inter-Regular";
}
#urbanform-styles .menu-columns > div.thin-column .menu .icon-logo {
  text-align: center;
  padding: 0;
  margin-top: 20px;
  color: #edeced;
  font-weight: bold;
}
#urbanform-styles .menu-columns > div.thin-column .menu .icon-logo img {
  width: 44px;
  height: 53px;
  margin: auto;
}
#urbanform-styles .menu-columns > div.thin-column .menu .icon-logo span {
  display: block;
  font-weight: bold;
  font-size: 14px;
}

.address_b {
  font-style: italic;
}

/* top menu css */
.ui.menu.top-menu {
  margin-bottom: 0;
  background: #faf9fa;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ui.menu.top-menu .item.geocoder {
  padding: 0;
  padding-left: 3px;
}
.ui.menu.top-menu .item.geocoder .mapboxgl-ctrl-geocoder {
  max-width: 100%;
}
.ui.menu.top-menu .item.logo {
  width: 20%;
  color: #5b577d;
  background: #f1f0f1;
  text-align: center;
  display: block;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .ui.menu.top-menu .item.logo {
    width: auto;
  }
}
/* sidebar css */
.main-columns .sidebar-container {
  background: #faf9fa;
  display: flex;
  flex-direction: row-reverse;
  transition: 0.5s;
  transition: 1.5s;
}
.main-columns .sidebar-container.slid-left {
  width: 10px;
}
.main-columns .sidebar-container .sidebar-menu {
  position: fixed;
  top: 0;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 10px;
  background: #f2f2f2;
  padding-top: 20px;
  height: 100vh;
  transition: 1.5s;
}
.main-columns .sidebar-container .sidebar-menu .checkbox-dropdown {
  min-width: 0px;
  width: 260px;
}
.main-columns
  .sidebar-container
  .sidebar-menu
  .checkbox-dropdown
  .checkbox-dropdown-container:hover {
  background: none !important;
  cursor: default;
}
.main-columns
  .sidebar-container
  .sidebar-menu
  .checkbox-dropdown
  .checkbox-dropdown-container
  .item {
  padding-bottom: 5px;
}
.main-columns .sidebar-container .sidebar-menu .substitute-dropdown-item {
  padding: 10px;
}
.main-columns .sidebar-container .slider-control {
  float: right;
  width: 10px;
  background: #f1f0f1;
  cursor: pointer;
  position: relative;
}
.main-columns .sidebar-container .slider-control:hover {
  background: #d0ced7;
}
.main-columns .sidebar-container .slider-control .slider-arrow {
  font-size: 8px;
  color: #aaa;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .main-columns .sidebar-container {
    position: absolute;
    z-index: 10;
  }
  .main-columns .sidebar-container .slider-control {
    width: 30px;
  }
  .main-columns .sidebar-container .menu-text {
    transform: rotate(270deg);
    font-size: 12px;
    margin-top: 2px;
    margin-left: 5px;
    pointer-events: inherit;
  }
  .main-columns .sidebar-container .data-window {
    position: fixed;
  }
}
/* map css */
#mapbox-map {
  width: 100%;
  height: 100vh;
}

.main-columns .map-container {
  transition: 0.5s;
}

/* dimmer css */
#urbanform-styles .dimmer {
  display: block !important;
  width: 76%;
}

#urbanform-styles .loader {
  font-size: 22px;
}

.ui.dimmer {
  background: transparent;
}

.ui.dimmer .ui.loader {
  color: #52c1f3;
}

.ui.dimmer .ui.loader:before {
  border-color: #52c1f3;
}

.ui.loader {
  left: calc(50% - 167px);
}

.mobile-loader.ui.loader {
  left: 50% !important;
}

.map-switcher {
  bottom: 76px;
  width: 180px;
  border: 2px solid white;
  color: #3c6cb0;
  background: #e0f2fe;
  position: absolute;
  margin-left: 16%;
  padding: 10px;
}

.map-switcher .primary {
  margin-left: 53px;
}

.sidebar-switcher {
  bottom: -861px;
  width: 112px;
  border: 2px solid white;
  color: #3c6cb0;
  background: #e0f2fe;
  margin-left: 58%;
  padding: 6px;
  margin-top: -38px;
}

.sidebar-switcher .primary {
  margin-left: 33px;
}

.logo-container .logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer !important;
}
.logo-container .logo img {
  width: 36%;
  height: auto;
}
.logo-container .logo h2 {
  margin: 0;
  font-size: 40px;
  font-weight: bolder !important;
}
.logo-container .logo p {
  font-size: 24px;
}
.logo-container .text-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  align-items: center;
}
.logo-container .text-wrap .text-line {
  margin-bottom: -8px;
}
.logo-container .text-wrap .sign-up-line {
  margin-bottom: 8px;
}
.logo-container .text-wrap p {
  margin: 0;
}
.logo-container .text-wrap img {
  width: 75%;
}

.un-authorized-modal-container .close {
  top: 0.5rem !important;
  right: 0.5rem !important;
  color: black !important;
}
