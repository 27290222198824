.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
  margin-top: 0px !important;
}
.section h2 {
  margin: 0 0 0px;
  font-weight: bolder;
  position: relative;
}
.section .heading-inline-desc {
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1 !important;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section .sub-title {
  margin-left: 63px;
  color: #5a636f;
}

.use-regulation p {
  color: #5a636f;
  margin: 0;
}

.data-header p {
  font-size: 12px;
}

.data-header .description {
  margin-left: 35px;
}
.data-header .description p {
  font-weight: 600;
  font-size: 15px;
}
