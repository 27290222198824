.data-header .container-styles {
  height: 364px;
  background: white;
}

.slider-section {
  border-top: 1px solid #f2f2f2;
  margin-top: 8px;
  padding: 18px;
}
