.feature-count-wrapper {
  min-width: 200px;
  z-index: 9;
  margin: 10px;
  font-size: 14px;
  color: #000;
  font-weight: bolder;
  margin-left: calc(50% - 100px);
  position: absolute;
}

.underlays-label {
  position: absolute;
  z-index: 1;
  top: -23px;
  left: 0;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.toggle-switcher {
  flex-direction: column;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.toggle-switcher span {
  display: inline-block;
  margin: auto 0;
}
.toggle-switcher .switch-container {
  display: flex;
  margin-top: 5px;
}
.toggle-switcher .switch-container div {
  border: 0.4px solid;
}
.toggle-switcher .switch-container .react-switch-handle {
  border: 1px solid !important;
  background: white !important;
}
.toggle-switcher .switch-container .bld-status {
  padding-left: 10px;
}

.value-03 {
  font-size: 14px !important;
  color: black !important;
  opacity: 0.8 !important;
  font-weight: normal !important;
}

.controls-box {
  background-color: rgba(255, 255, 255, 0.25);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 255px;
  width: 160px;
  border-radius: 8px;
}

.controls-box2 {
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 366px;
  min-height: fit-content;
  width: 231px;
  border-radius: 8px;
  margin: 10px 0 0 10px;
  padding: 15px;
  box-shadow: 4px 4px 4px 0px #bdbdbd;
}

.controls-box-mobile {
  background-color: rgba(255, 255, 255, 0.25) !important;
  height: 205px !important;
}

.city-switcher {
  flex-direction: column;
}
.city-switcher div {
  width: 205px;
}
.city-switcher .ui.basic.button {
  display: flex;
  color: #000 !important;
  background-color: #fdb3e2 !important;
  border-radius: 11px;
  line-height: 0.75em;
}
.city-switcher .ui.basic.button span {
  margin-left: 9px;
  margin-top: 5px;
  font-family: "Inter-Regular";
}
.city-switcher .ui.basic.button:hover {
  background-color: #fdb3e2 !important;
  color: #fff !important;
}
.city-switcher button {
  margin-bottom: 10px !important;
  width: 100%;
}

.underlays-switcher {
  flex-direction: column;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.underlays-switcher span {
  display: inline-block;
  margin: auto 0;
}
.underlays-switcher .switch-container {
  display: flex;
  margin-top: 5px;
}
.underlays-switcher .switch-container .react-switch-bg {
  border: 1px solid !important;
}
.underlays-switcher .switch-container .react-switch-handle {
  border: 1px solid !important;
  background: white !important;
}
.underlays-switcher .switch-container .bld-status {
  padding-left: 10px;
}

.envelope-3d-switcher {
  flex-direction: column;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.envelope-3d-switcher span {
  display: inline-block;
  margin: auto 0;
}
.envelope-3d-switcher .switch-container {
  display: flex;
  margin-top: 5px;
}
.envelope-3d-switcher .switch-container .react-switch-bg {
  border: 1px solid !important;
}
.envelope-3d-switcher .switch-container .react-switch-handle {
  border: 1px solid !important;
  background: white !important;
}
.envelope-3d-switcher .switch-container .envelope-3d-status {
  padding-left: 10px;
}

.mapboxgl-ctrl-top-left {
  top: 25px !important;
}

.mapboxgl-ctrl-geocoder {
  box-shadow: unset !important;
  width: 97% !important;
  max-width: 100% !important;
  margin: 0px 0px 0 5px !important;
  border-radius: 8px !important;
}
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-search {
  display: none;
}
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--input {
  padding-right: 10px;
  height: 50px !important;
  font-size: 18px !important;
  font-family: "Inter-Regular" !important;
  padding: 5px 25px !important;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 12px !important;
}

.mapboxgl-ctrl-geocoder--icon {
  top: 13px !important;
}
