.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  color: black;
  font-weight: bolder;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 16px;
  width: unset;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}

.identifiers .heading-text {
  width: max-content !important;
}
.identifiers .section-description {
  margin-top: unset;
}
