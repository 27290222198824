.default-help h1 {
  color: #4f4f4f;
  font-size: 40px;
  font-weight: bold;
  padding: 10px;
  font-family: "Inter-Black";
}
.default-help .d-help-card {
  background-color: white;
  color: black;
  border-radius: 12px;
}
.default-help .d-help-card .d-help-header {
  border-bottom: 1px solid #f2f2f2;
}
.default-help .d-help-card .d-help-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}
.default-help .d-help-card .d-help-body {
  padding: 15px 18px;
  display: flex;
}
.default-help .d-help-card .d-help-body .image {
  position: absolute;
}
.default-help .d-help-card .d-help-body p {
  margin-left: 42px;
  font-size: 17px;
  line-height: 22px;
  opacity: 0.7;
}
.default-help .d-help-card .d-help-body .ui.form .field .ui.input {
  width: 100%;
}
.default-help .d-help-card .d-help-body .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  max-width: 100%;
}
.default-help .d-help-card .d-help-body .ui.transparent.input > input {
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.default-help .btn-login {
  margin-top: 67px;
  position: absolute;
  left: -16px;
}
.default-help .btn-login .button {
  display: flex;
  background-color: #ffd600;
  border-radius: 10px;
  width: 156px;
  height: 53px;
}
.default-help .btn-login span {
  text-transform: uppercase;
  color: black;
  margin-top: 7px;
  margin-left: 33px;
  font-size: 13px;
  width: 86px;
  text-align: justify;
  line-height: 15px;
}
.default-help .btn-create-acc {
  margin-top: 75px;
}
.default-help .btn-create-acc .button {
  display: flex;
  background-color: #ffd600;
  border-radius: 10px;
}
.default-help .btn-create-acc span {
  text-transform: uppercase;
  color: black;
  margin-top: 4px;
  margin-left: 8px;
  font-size: 13px;
  width: 86px;
  text-align: justify;
  line-height: 15px;
}

@media only screen and (max-width: 500px) {
  .default-help h1 {
    font-size: 30px;
  }
}
.current-query {
  margin-top: 24px;
}
.current-query .current-query-filter-card {
  background-color: white;
  color: black;
  max-height: 338px;
  border-radius: 12px;
  overflow-y: auto;
}
.current-query .current-query-filter-card .current-query-header {
  border-bottom: 1px solid #f2f2f2;
}
.current-query .current-query-filter-card .current-query-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}
.current-query .current-query-filter-card .current-query-body {
  padding: 15px 18px;
  display: flex;
  overflow-y: scroll;
}
.current-query
  .current-query-filter-card
  .current-query-body
  .current-query-sub-heading {
  font-size: 13px;
}
.current-query
  .current-query-filter-card
  .current-query-body
  .current-query-lot {
  display: flex;
  align-items: center;
}
.current-query .current-query-filter-card .current-query-body .current-min-max {
  font-size: 15px;
  margin-bottom: 20px;
}

.current-query
  .current-query-filter-card
  .current-query-body
  .current-query-value {
  font-size: 15px;
  font-weight: 600;
  line-height: 0.2;
  margin-bottom: 20px;
  min-height: fit-content !important;
}

.default-filter {
  margin-top: 24px;
}
.default-filter .d-filter-card {
  background-color: white;
  color: black;
  border-radius: 12px;
}
.default-filter .d-filter-card .d-filter-header {
  border-bottom: 1px solid #f2f2f2;
}
.default-filter .d-filter-card .d-filter-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}
.default-filter .d-filter-card .d-filter-body {
  padding: 15px 18px;
  display: flex;
}
.default-filter .d-filter-card .d-filter-body .image {
  position: absolute;
}
.default-filter .d-filter-card .d-filter-body p {
  margin-left: 42px;
  font-size: 16px;
  line-height: 21px;
  opacity: 0.7;
}

.default-info {
  margin-top: 13px;
}
.default-info .d-info-card {
  background-color: white;
  color: black;
  border-radius: 12px;
}
.default-info .d-info-card .d-info-header {
  border-bottom: 1px solid #f2f2f2;
}
.default-info .d-info-card .d-info-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}
.default-info .d-info-card .d-info-body {
  padding: 15px 18px;
  display: flex;
}
.default-info .d-info-card .d-info-body .image {
  position: absolute;
}
.default-info .d-info-card .d-info-body p {
  margin-left: 42px;
  font-size: 17px;
  line-height: 23px;
  opacity: 0.7;
  font-family: "Inter-Regular";
}
.default-info .d-info-card .d-info-about-header {
  border-bottom: 1px solid #f2f2f2;
}
.default-info .d-info-card .d-info-about-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
  opacity: 0.7;
  background-color: #d9d9d9;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  font-family: "Inter-Bold";
}
.default-info .d-info-card .d-info-about-body {
  padding: 15px 18px;
  display: flex;
  background-image: linear-gradient(#ffbded 0%, #85e2ff 100%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.default-info .d-info-card .d-info-about-body .image {
  position: absolute;
}
.default-info .d-info-card .d-info-about-body p {
  margin-left: 42px;
  font-size: 16px;
  line-height: 23px;
  opacity: 0.7;
  font-family: "Inter-Regular";
}

.default-user h1 {
  color: #4f4f4f;
  font-size: 40px;
  font-weight: bold;
  padding: 10px;
}
.default-user .d-user-card {
  background-color: white;
  color: black;
  border-radius: 12px;
}
.default-user .d-user-card .d-user-header {
  border-bottom: 1px solid #f2f2f2;
}
.default-user .d-user-card .d-user-header h3 {
  padding: 15px 18px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}
.default-user .d-user-card .d-user-body {
  padding: 15px 18px;
}
.default-user .d-user-card .d-user-body .image {
  position: absolute;
}
.default-user .d-user-card .d-user-body p {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  opacity: 0.7;
}
.default-user .d-user-card .d-user-body .ui.form .field .ui.input {
  width: 100%;
}
.default-user .d-user-card .d-user-body .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  max-width: 100%;
}
.default-user .d-user-card .d-user-body .ui.transparent.input > input {
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.default-user .btn-login {
  margin-top: 67px;
  position: absolute;
  left: -16px;
}
.default-user .btn-login .button {
  display: flex;
  background-color: #ffd600;
  border-radius: 10px;
  width: 156px;
  height: 53px;
}
.default-user .btn-login span {
  text-transform: uppercase;
  color: black;
  margin-top: 7px;
  margin-left: 33px;
  font-size: 13px;
  width: 86px;
  text-align: justify;
  line-height: 15px;
}
.default-user .btn-create-acc {
  margin-top: 75px;
}
.default-user .btn-create-acc .button {
  display: flex;
  background-color: #ffd600;
  border-radius: 10px;
}
.default-user .btn-create-acc span {
  text-transform: uppercase;
  color: black;
  margin-top: 4px;
  margin-left: 8px;
  font-size: 13px;
  width: 86px;
  text-align: justify;
  line-height: 15px;
}
