.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  color: black;
  font-weight: bolder;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}

.far_bonus_a
  .far_bonus_b
  .far_bonus_c
  .far_bonus_d
  .far_bonus_e
  .far_bonus_f
  .far_bonus_g
  .far_bonus_h
  .far_bonus_i
  .far_bonus_j
  .far_bonus_k
  .far_bonus_l {
  padding: 12px;
  margin-left: 58px;
}

.far_bonus_a {
  margin-top: 16px;
}

.far_adjusted_a {
  margin-top: 12px;
}

.far_adjusted_b {
  margin-top: -11px;
}

.far_adjusted_b .section-data {
  margin-top: -38px;
}

.far .title-desc {
  margin-top: -2px;
  margin-bottom: 19px;
}
