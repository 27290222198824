.data-header {
  padding: 5px;
  border: 2px solid #fafcfc;
  border-radius: 11px;
  background: #fafcfc;
}
.data-header h2 {
  width: 350px;
  color: #4f4f4f;
  margin: 0 0 11px;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 8px;
  margin-left: 20px;
  text-transform: uppercase;
  font-family: "Inter-Bold";
}
.data-header h3 {
  position: absolute;
  top: 17px;
  color: #4f4f4f;
  margin: 0 0 11px;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 8px;
  margin-left: 20px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}
.data-header p {
  font-weight: normal;
  color: #818286;
  font-size: 16px;
}
.data-header .header-logo {
  height: 30px;
  margin: 5px 16px auto;
}
.data-header .sub-title {
  margin-left: 35px;
}
