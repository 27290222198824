.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  font-weight: bolder;
  font-size: 35px;
  color: #5a636f;
}
.section .section-data-category {
  margin-left: 19px;
  margin-top: 18px;
}
.section .title-text {
  margin-left: 4px;
  margin-top: -38px;
  font-size: 18px;
  font-weight: normal;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
  width: 235px;
}
.section li {
  margin: 0 0 10px;
  font-weight: normal;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}
.section .sub-heading-2 {
  margin-top: 15px;
  padding: 10px;
}

.property-more {
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  margin-left: 3px;
  margin-top: -28px;
}

.property-lock-image {
  width: 30px;
  margin-left: -32px;
}

.property-link-image {
  width: 30px;
  margin-left: -34px;
  margin-top: -10px;
}

.sub-heading-lock {
  margin-top: -88px !important;
  margin-left: 10px;
  font-size: 23px !important;
}

.sourceHr hr {
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.property-source-text {
  margin-left: 30px;
  display: flex;
}
.property-source-text a {
  display: block;
  text-decoration: none;
  font-size: 18px !important;
  color: #69beee;
  cursor: pointer;
}

.summary-data .container-styles {
  height: auto;
}
.summary-data .more {
  height: 18px;
}

.property-main-more .property-more {
  margin-top: -20px;
  margin-bottom: 14px;
  width: 50%;
  width: 172px !important;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
  margin-left: 58px;
}
.property-main-more .property-more a {
  color: #818286;
}

.summary-data .footer-more {
  padding: 12px;
  margin-left: 50px;
}
.summary-data .footer-more a {
  color: #69beee;
  cursor: pointer;
  text-decoration: none !important;
}

.link-image-footer {
  width: 8%;
  margin-left: 38px;
  margin-top: 9px;
}

.footer-more-use-regulation {
  margin-bottom: 12px;
  margin-top: 12px;
  margin-left: 3px;
}
.footer-more-use-regulation a {
  color: black;
  opacity: 0.6 !important;
  cursor: pointer;
}

.footer-more-use-parking {
  margin-left: -4px;
  padding: 12px;
}
.footer-more-use-parking a {
  color: #818286;
  cursor: pointer;
}

.lock-hr {
  margin-right: 0px;
  margin-bottom: 18px;
}

.plan_districts_b {
  width: 235px;
}

.plan_districts_c {
  width: 235px;
}

.plan_districts_d {
  width: 235px;
}

.ul-align {
  margin-left: -23px;
}

.development_ratio_line hr {
  margin-bottom: -7px;
}

.card-label {
  display: flex;
}

.info-tabs {
  margin: 0px 10px;
}
.info-tabs .card-label {
  margin-left: 20px;
}
.info-tabs .card-label p {
  color: black;
  opacity: 0.7;
}
.info-tabs .card-label span {
  color: #000 !important;
  font-weight: bold;
  word-break: break-word;
}
.info-tabs .card-label .use-small-text {
  margin-left: 40px;
  word-break: normal;
}

.main-source {
  display: flex;
  margin: 0px 10px 0px 30px;
}
.main-source span {
  color: black;
  font-size: 18px;
  opacity: 0.7;
}
.main-source .src_body {
  height: 20px;
  font-size: 14px;
}
.main-source .src_body a {
  margin-left: 8px;
  color: #69beee;
  cursor: pointer;
}
