.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  font-weight: bolder;
  position: relative;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}

.existing_structure_b {
  font-size: 28px;
  font-weight: normal;
  color: #5a636f;
}

.existing-structure hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.existing-structure .property_owner_a h2 {
  width: 235px;
}

.existing-structure .property_owner_b {
  margin-top: 18px;
}

.existing-structure .property_owner_d {
  margin-top: -15px;
  margin-bottom: 18px;
}
