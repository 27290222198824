.p-methods .choose-header {
  color: #fff;
  background: #43c1f3;
  padding: 24px 16px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  opacity: 0.5;
  margin-right: 10px;
  margin-bottom: 28px;
}
.p-methods .ui.radio.checkbox .box,
.p-methods .ui.radio.checkbox label {
  padding-left: 1.85714em;
  font-size: 18px;
  font-weight: bolder;
  opacity: 0.5;
}
.p-methods .upgrade-plan-btn {
  margin: 30px;
}
.p-methods .upgrade-plan-btn .upgrade-button.ui.button {
  background: #ffd600;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.p-methods .upgrade-plan-btn .upgrade-button.ui.button:hover {
  background: #43c1f3;
}
.p-methods .edit-card-info {
  border: 1px solid #000;
  border-radius: 5px;
  margin: 10px 20px 10px 10px;
}
.p-methods .edit-card-info .edit-form {
  padding: 20px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: -20px;
}
.p-methods .edit-card-info .edit-form .ui.form .field {
  margin: 0 0 0.5em;
}
.p-methods .edit-card-info .edit-form .ui.form select {
  border: none;
}
.p-methods .edit-card-info .edit-form .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
}
.p-methods .edit-card-info .edit-form .ui.form .field .select {
  display: none;
}
.p-methods .edit-card-info .edit-form .ui.form .field > label {
  color: #616163 !important;
  padding-left: 10px;
  margin-top: 16px;
  font-weight: 500;
}
.p-methods .edit-card-info .edit-form .ui.form .field.error .input {
  border: 1px solid;
}
.p-methods .edit-card-info .edit-form .edit-form-button {
  display: flex;
  justify-content: center;
  margin: 16px;
}
.p-methods .edit-card-info .edit-form .edit-form-button .button {
  color: #000;
  font-size: 18px;
  padding: 20px 20px;
  background: #f8d9e6;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  width: 100%;
  border: none;
  cursor: pointer;
}
.p-methods .edit-card-info .edit-form .edit-form-button .button:hover {
  background: #43c1f3;
}
.p-methods .edit-card-info .edit-form .cancel-button {
  display: flex;
  justify-content: center;
  margin: 16px;
}
.p-methods .edit-card-info .edit-form .cancel-button .button {
  color: #000;
  font-size: 18px;
  padding: 20px 20px;
  background: #43c1f3;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  width: 100%;
  border: none;
  cursor: pointer;
}
.p-methods .edit-card-info .edit-form .cancel-button .button:hover {
  background: #f8d9e6;
}
.p-methods .card-payment-method {
  display: flex;
  justify-content: space-between;
  height: 65px;
  background: #d4effa;
  border-radius: 12px;
  margin: 12px 20px 12px 8px;
}
.p-methods .card-payment-method .card-payment-method-action {
  margin-right: 15px;
  margin-top: 23px;
}
.p-methods .card-payment-method .card-payment-method-action a {
  color: #000 !important;
  opacity: 0.8;
  cursor: pointer;
}
.p-methods .card-payment-method #react-select-2-listbox {
  color: #000;
}
.p-methods .card-payment-method .radio-fields .radio label {
  position: absolute;
  top: -34px;
}
