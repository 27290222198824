.filter-container .filter-heading {
  padding: 16px;
  margin-bottom: 16px;
}
.filter-container .filter-heading h1 {
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 0px;
}
.filter-container .filter-item {
  margin-top: 10px;
}
.filter-container .filter-item .filter-contorls-btns .image {
  margin: auto 0;
}
.filter-container .filter-item .filter-contorls-btns .filter-icon {
  width: 35px !important;
}
.filter-container .filter-item .filter-contorls-btns span {
  color: #69beee;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  margin: auto 0px auto 9px;
  text-align: justify;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter-Regular";
}
.filter-container .filter-item .filter-contorls-btns .centered {
  width: 100%;
  opacity: 1;
}
.filter-container .filter-item .button {
  display: flex !important;
  background: rgba(255, 255, 255, 0.35) !important;
  border-radius: 12px !important;
  padding: 10px 15px !important;
  height: 55px;
}
.filter-container .filter-item .active,
.filter-container .filter-item:hover {
  background: white !important;
}
.filter-container .filter-item .active span,
.filter-container .filter-item:hover span {
  font-weight: 700;
}
.filter-container .bottom {
  position: relative;
  top: 18%;
  transform: translateY(0);
}
.filter-container .bottom .see-result-btn {
  margin: 20px 4px 8px 0px;
}
.filter-container .bottom .see-result-btn .reset-button.ui.button {
  display: flex;
  background: rgba(255, 255, 255, 0.35) !important;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 12px;
  transition: 0.3s ease-in-out all;
  padding: 10px 25px !important;
  height: 60px;
}
.filter-container .bottom .see-result-btn .reset-button.ui.button .image-size {
  height: 30px;
  cursor: pointer;
}
.filter-container .bottom .see-result-btn .reset-button.ui.button span {
  margin-left: 15px;
  margin-top: 9px;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Regular";
}
.filter-container .bottom .see-result-btn .reset-button.ui.button:hover {
  background: #43c1f3;
}
.filter-container .bottom .see-result-btn .result-button.ui.button {
  display: flex;
  background: #ffa5dc;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 10px 25px !important;
  border-radius: 12px;
  transition: 0.3s ease-in-out all;
}
.filter-container .bottom .see-result-btn .result-button.ui.button .image-size {
  height: 30px;
  cursor: pointer;
}
.filter-container .bottom .see-result-btn .result-button.ui.button span {
  margin-left: 12px;
  margin-top: 9px;
  font-size: 12px;
  text-transform: uppercase;
  height: 30px;
  font-family: "Inter-Regular";
}
.filter-container .bottom .see-result-btn .result-button.ui.button:hover {
  background: #43c1f3;
}
.filter-container .see-result-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.filter-container .see-result-icons .image-size {
  cursor: pointer;
  margin: 0 auto;
  margin-top: 6px;
}
.filter-container .rotate {
  padding: 6px;
  background: white;
  border-radius: 12px;
  margin: 4px;
  width: 95px;
  height: 50px;
}
.filter-container .file {
  padding: 6px;
  background: white;
  border-radius: 12px;
  margin: 4px;
  width: 95px;
  height: 50px;
}
.filter-container .heart {
  padding: 6px;
  background: white;
  border-radius: 12px;
  margin: 4px;
  cursor: pointer;
}
.filter-container .file {
  padding: 10px 8px 10px 13px;
  background: #f6fbff;
  border-radius: 12px;
  margin: 4px;
  cursor: pointer;
}
.filter-container .heart {
  padding: 10px 8px 10px 13px;
  background: #f6fbff;
  border-radius: 12px;
  margin: 4px;
  cursor: pointer;
}
.filter-container .btn-active {
  background: #f6fbff;
  padding: 20px 20px 10px 20px;
  margin: 20px 4px auto -11px;
  border-top-right-radius: 10px;
}
.filter-container .icon-active {
  background: #f6fbff;
  padding: 0px 16px 0px 189px;
  margin: auto 5px auto -17px;
  border-bottom-right-radius: 10px;
}
.filter-container .unverified-active {
  padding: 0px 16px 0px 286px !important;
}

.slider-section .ui.input {
  width: 165px;
  position: relative;
}

.existing-structure-section .ui.input {
  width: 112px;
}
