.vertical-center {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.section .value-01 {
  font-size: 26pt !important;
  color: black;
  opacity: 0.6 !important;
}

.section .value-02 {
  font-size: 13pt !important;
  color: black;
  font-weight: bold !important;
}

.section .value-03 {
  font-size: 13pt !important;
  color: black;
  font-weight: bold !important;
}

.section .link {
  font-size: 18px !important;
}

.footer-section .link {
  font-size: 18px !important;
}

.section .value-title {
  font-size: 18px !important;
  color: #5a636f;
  width: auto;
}

.section .li-text {
  font-size: 14pt !important;
  color: black;
  opacity: 0.6 !important;
}

.section a {
  font-size: 12pt !important;
}

.section .link-title {
  font-size: 18px !important;
}

.section .sub-text {
  color: #000000;
  font-size: 10pt;
  opacity: 0.6;
}

.footer-section {
  display: flex;
  background: #fafcfc;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
}

.link-image {
  background: #fafcfc;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.help-section .value-02 {
  font-size: 20pt !important;
  color: black;
  opacity: 0.6 !important;
}

.help-section .value-03 {
  font-size: 14pt !important;
  color: black;
  opacity: 0.8 !important;
}

.help-section .sub-text {
  color: #000000;
  font-size: 10pt;
  opacity: 0.6;
}

.payment-method-section .value-01 {
  font-size: 26pt !important;
  color: black !important;
  font-weight: normal !important;
}
.payment-method-section .value-02 {
  font-size: 15pt !important;
  color: black !important;
  font-weight: normal !important;
}
.payment-method-section .value-03 {
  font-size: 14pt !important;
  color: black !important;
  font-weight: normal !important;
}
.payment-method-section .value-title {
  font-size: 12pt !important;
  color: black !important;
  font-weight: normal !important;
}
.payment-method-section .sub-text {
  color: #000000;
  font-size: 10pt;
  opacity: 0.6;
}

.home-content .value-02 {
  font-size: 20pt !important;
  color: black;
}

.see-result-icons .sub-text {
  color: #000000;
  font-size: 10pt;
  opacity: 0.6;
}

.un-authorized-modal .value-01 {
  font-size: 26pt;
  color: black !important;
  opacity: 0.6 !important;
  font-weight: normal !important;
}
.un-authorized-modal .value-03 {
  font-size: 14pt;
  color: black !important;
  opacity: 0.8 !important;
  font-weight: normal !important;
}

@media screen and (max-width: 326px), screen and (max-height: 550px) {
  #urbanform-styles
    .sidebar-menu
    .home-content
    .other-info
    .zoning-info
    .bg-content
    p {
    color: black !important;
    opacity: 0.6 !important;
    font-weight: normal !important;
    font-size: 11pt !important;
  }
}
