.payment-form {
  max-width: 800px;
  padding: 0 16px 0 6px;
  margin: 0 auto 0 27px;
}
.payment-form .ui.pay-btn {
  color: #000;
  padding: 16px;
  background: #fddb6e;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  border-color: transparent;
  margin: 32px 24px 16px 0;
  font-size: 18px;
}
.payment-form .ui.pay-btn:hover {
  background: #43c1f3;
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}
.payment-form .button[disabled] {
  opacity: 0.6;
}
.payment-form .input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 12px 0 12px 0;
  max-width: 100%;
  padding: 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #000;
  letter-spacing: 0.025em;
  width: 500px;
}
.payment-form input::placeholder {
  color: #aab7c4;
}

.result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/
.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 12px 0 12px 0;
  max-width: 500px;
  padding: 14px;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}

.payment-method-section {
  border-top: 1px solid #f2f2f2;
}
.payment-method-section .ui.form .field {
  margin-bottom: 0 !important;
}
.payment-method-section .section-display {
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 3px;
}
.payment-method-section .section-display .pay-btn {
  color: #000;
  padding: 16px;
  background: #fddb6e;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  border-color: transparent;
  margin: 0 auto;
  width: 90% !important;
  font-size: 18px;
}
.payment-method-section .section-display .pay-btn:hover {
  background: #43c1f3;
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}
.payment-method-section .radio-group {
  display: flex;
  width: 235px;
}
.payment-method-section .radio-group label {
  padding-left: 14px;
}
.payment-method-section .a-gap {
  margin-top: 12px !important;
}
.payment-method-section .b-gap {
  margin-top: 18px !important;
}
.payment-method-section .horizontal-line {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 81%;
}
.payment-method-section .field {
  margin-bottom: 12px !important;
}
.payment-method-section .terms-service {
  margin-left: 13px;
}
.payment-method-section .terms-service a {
  display: block;
}
