.data-window {
  overflow: hidden;
  overflow-y: auto;
  padding-left: 13px;
  padding-right: 13px;
  background: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 72px;
  height: 100vh;
  width: 375px;
}
.data-window .checkbox-dropdown {
  min-width: 0px;
  width: 260px;
}
.data-window .checkbox-dropdown .checkbox-dropdown-container:hover {
  background: none !important;
  cursor: default;
}
.data-window .checkbox-dropdown .checkbox-dropdown-container .item {
  padding-bottom: 5px;
}
.data-window .substitute-dropdown-item {
  padding: 10px;
}
.data-window .slider-control {
  float: right;
  width: 10px;
  background: #f1f0f1;
  cursor: pointer;
  position: relative;
}
.data-window .slider-control:hover {
  background: #d0ced7;
}
.data-window .slider-control .slider-arrow {
  font-size: 8px;
  color: #aaa;
  vertical-align: middle;
}
.data-window .purchase-plan-btn {
  margin: 30px;
}
.data-window .purchase-plan-btn .ui.button {
  background: #fddb6e;
  line-height: 16px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 18px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.data-window .purchase-plan-btn .ui.button:hover {
  background: #43c1f3;
}
.data-window .back {
  width: 55px;
  margin-left: 16px;
  margin-bottom: 10px;
}
