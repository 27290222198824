.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  font-weight: bolder;
  position: relative;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}
.section .height_limit_b .sub-heading {
  width: 220px;
}
