.forget-password {
  color: #000;
  padding: 5px 20px 20px;
}
.forget-password .header {
  display: flex;
  color: #fff;
  background: #43c1f3;
  padding: 24px 16px;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  opacity: 0.5;
}
.forget-password .header .forget-icon {
  margin-top: 10px;
  font-size: 20px;
}
.forget-password h4 {
  font-size: x-large;
  margin: auto 0 auto 16px;
}
.forget-password .forget-password-section {
  margin-top: 26px;
}
.forget-password .forget-password-section .ui.form .field {
  margin: 0 0 0.5em;
}
.forget-password .forget-password-section .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
}
.forget-password .forget-password-section .ui.form .field > label {
  color: #616163 !important;
  padding-left: 12px;
  margin-top: 16px;
  font-weight: 500;
}
.forget-password .forget-password-section .ui.form .field.error .input {
  border: 1px solid;
}
.forget-password .forget-password-section .active {
  background: #fff !important;
  font-weight: normal !important;
}
.forget-password .forget-password-section .forget-password-btn {
  display: flex;
  justify-content: center;
  margin: 16px;
}
.forget-password .forget-password-section .forget-password-btn .button {
  color: #000;
  font-size: 18px;
  padding: 20px 20px;
  background: #f8d9e6;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.forget-password .forget-password-section .forget-password-btn .button:hover {
  background: #43c1f3;
}
.forget-password .forget-password-section .forget-password-btn .button a {
  color: #fff;
  text-decoration: none;
}
