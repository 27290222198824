.saved-section {
  margin: 0px;
}
.saved-section .section-data:hover {
  background-color: #d4effa;
  cursor: pointer;
}
.saved-section a {
  color: #cce2ff;
}
.saved-section .heading-text {
  padding: 10px 10px 0px 30px;
  color: #69beee;
  word-break: break-word;
  text-transform: uppercase;
}
.saved-section .heading-text-city {
  margin-top: -6px;
  padding: 10px 10px 10px 10px;
  margin-top: -27px;
}
.saved-section .color {
  color: #0e1801;
  word-break: break-word;
}
.saved-section h2 {
  font-weight: bolder;
  position: relative;
}
.saved-section .sub-title {
  margin-left: 63px;
  color: black;
}
.saved-section .align-date {
  margin-left: 30px;
  font-size: 12px;
}

.acc_data_window {
  padding: 5px 20px 20px;
}
.acc_data_window .property-data {
  background: white;
  border-radius: 12px;
  margin-top: 25px;
}

.property-heading {
  border-bottom: 1px solid #f2f2f2;
}
.property-heading h2 {
  color: black;
  text-align: center;
  margin-top: 17px !important;
  margin-left: 30px;
  display: flex;
  padding: 10px 0;
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 22px;
  font-family: "Inter-Bold";
}

.filter-left {
  width: 80%;
}

.delete-icon {
  width: 20%;
}
.delete-icon img {
  width: 40%;
  float: right;
  margin-top: 20px;
  margin-right: 10px;
}

.delete-icon img:hover {
  opacity: 1;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.3s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
}
