.dashboard-container {
  margin-top: 57px;
}
.dashboard-container .profile-section {
  padding: 16px;
  margin-bottom: 16px;
}
.dashboard-container .profile-section h1 {
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 0px;
}
.dashboard-container .account-item {
  margin-top: 10px;
}
.dashboard-container .account-item .account-contorls-btns .image {
  margin: auto 0;
}
.dashboard-container .account-item .account-contorls-btns span {
  font-size: 15px;
  display: inline-block;
  margin: auto 1px auto 15px;
  color: #69beee;
  text-transform: uppercase;
  text-align: justify;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter-Regular";
}
.dashboard-container .account-item .account-contorls-btns .centered {
  width: 100%;
  opacity: 1;
}
.dashboard-container .account-item .button {
  display: flex !important;
  background: rgba(255, 255, 255, 0.35) !important;
  border-radius: 12px !important;
  padding: 10px 15px !important;
  height: 55px;
}
.dashboard-container .account-item .active,
.dashboard-container .account-item:hover {
  background: white !important;
}
.dashboard-container .account-item .active span,
.dashboard-container .account-item:hover span {
  font-weight: 700;
}
.dashboard-container .account-item-no-hover {
  margin-top: 10px;
}
.dashboard-container .account-item-no-hover .account-contorls-btns .image {
  margin: auto 0;
}
.dashboard-container .account-item-no-hover .account-contorls-btns span {
  font-size: 15px;
  display: inline-block;
  margin: auto 1px auto 15px;
  color: #69beee;
  text-transform: uppercase;
  text-align: justify;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter-Regular";
}
.dashboard-container .account-item-no-hover .account-contorls-btns .centered {
  width: 100%;
  opacity: 1;
}
.dashboard-container .account-item-no-hover .button {
  display: flex !important;
  background: rgba(255, 255, 255, 0.35) !important;
  border-radius: 12px !important;
  padding: 10px 15px !important;
  height: 55px;
}
.dashboard-container .grayed-text {
  color: #818286;
  font-size: x-large;
  text-align: center;
  margin: 16px 0;
}
.dashboard-container .contact-support {
  position: absolute;
  bottom: 0;
  margin-right: 10px;
  margin-bottom: 15px;
  background-color: #43c1f3;
  border-radius: 14px;
}
.dashboard-container .contact-support a {
  display: flex;
  color: #000;
  font-size: 18px;
  padding: 10px 25px;
  background: #43c1f3 !important;
  border-radius: 12px;
  transition: 0.3s ease-in-out all;
}
.dashboard-container .contact-support a .image {
  width: 40px;
  margin: auto 0;
  width: 35px !important;
  color: white;
}
.dashboard-container .contact-support a span {
  font-size: 14px;
  display: inline-block;
  margin: auto 20px;
  color: white;
  text-transform: uppercase;
}
.dashboard-container .contact-support a:hover {
  background: #fff;
}

.upgrade-container .upgrade-plan-btn {
  margin-top: 10px;
}
.upgrade-container .upgrade-plan-btn a {
  display: flex;
  background-color: #ffd600;
  border-radius: 10px;
  padding: 20px 20px;
  position: absolute;
}
.upgrade-container .upgrade-plan-btn span {
  text-transform: uppercase;
  color: black;
  margin-top: 4px;
  margin-left: 8px;
  font-size: 13px;
  width: 86px;
  text-align: justify;
  line-height: 15px;
}

.account-activation .activation-header {
  color: #fff;
  background: #43c1f3;
  padding: 24px 16px;
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  opacity: 0.5;
  margin-right: 10px;
  margin-bottom: 28px;
}
.account-activation .ui.form {
  padding-right: 13px;
}
.account-activation .ui.form .field {
  margin: 0 0 0.5em;
}
.account-activation .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
}
.account-activation .ui.form .field > label {
  color: #616163 !important;
  padding-left: 10px;
  margin-top: 16px;
  font-weight: 500;
}
.account-activation .ui.form .field.error .input {
  border: 1px solid;
}
.account-activation .account-activation-btn {
  display: flex;
  justify-content: center;
  margin: 16px;
}
.account-activation .account-activation-btn .button {
  color: #000;
  font-size: 18px;
  padding: 20px 20px;
  background: #f8d9e6;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.account-activation .account-activation-btn .button:hover {
  background: #43c1f3;
}
.account-activation .account-activation-btn .button a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 500px) {
  .dashboard-container .account-item .account-contorls-btns .image {
    width: 34px;
  }
  .dashboard-container .account-item .account-contorls-btns span {
    font-size: 16px !important;
  }
}
