.help-container {
  margin-top: 88px;
}
.help-container .short-description {
  margin-top: 40px;
}
.help-container .short-description p {
  color: #000000;
  font-size: 18px;
  opacity: 0.6;
}
.help-container .frequent-question img {
  width: 5%;
  margin-right: 5px;
}
.help-container .link-details {
  margin-top: 50px;
}
.help-container .link-details a {
  color: black;
}
.help-container .help-content {
  margin-right: 2px;
}
.help-container .help-item {
  margin-top: 10px;
}
.help-container .help-item .help-controls-btn .image {
  margin: auto 0;
}
.help-container .help-item .help-controls-btn .info-icon {
  width: 35px !important;
}
.help-container .help-item .help-controls-btn span {
  font-size: 14px;
  display: inline-block;
  margin: auto 0px auto 9px;
  color: #69beee;
  text-transform: uppercase;
  text-align: justify;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter-Regular";
}
.help-container .help-item .help-controls-btn .centered {
  width: 100%;
  opacity: 1;
}
.help-container .help-item .button {
  display: flex !important;
  background: rgba(255, 255, 255, 0.35) !important;
  border-radius: 12px !important;
  padding: 10px 15px !important;
  height: 55px;
}
.help-container .help-item .active,
.help-container .help-item:hover {
  background: white !important;
}
.help-container .help-item .active span,
.help-container .help-item:hover span {
  font-weight: 700;
}
.help-container .footer-content {
  position: absolute;
  bottom: 8px;
  left: 3px;
}
.help-container .footer-content .help-item span {
  font-size: 13px;
  display: inline-block;
  color: #69beee;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter-Regular";
}
.help-container .footer-content p {
  color: #69beee;
  font-size: 15px;
  opacity: 0.6;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}
.help-container .footer-content a {
  color: #69beee;
}

@media only screen and (max-width: 500px) {
  .help-container .help-item .help-controls-btn .image {
    width: 34px;
  }
  .help-container .help-item .help-controls-btn span {
    font-size: 16px !important;
  }
}
