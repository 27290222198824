.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  font-weight: bolder;
  position: relative;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section li {
  font-size: 20px;
  color: #818286;
  margin: 0 0 10px;
  font-weight: normal;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}

.residential-unit .more {
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  margin-left: 74px;
  margin-top: 5px;
  cursor: pointer;
}
.residential-unit .more a {
  color: #818286;
}
