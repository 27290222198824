#urbanform-styles {
  color: #fff;
}
#urbanform-styles .side-menu .icon-items .icon-item {
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}
#urbanform-styles .side-menu .icon-items .active-content {
  opacity: 1 !important;
}
#urbanform-styles .bottom-icons {
  position: absolute;
  bottom: 12px;
}
#urbanform-styles .sidebar-menu {
  padding: 20px 10px 0 10px;
}
#urbanform-styles .sidebar-menu #mapbox-geocoder-home,
#urbanform-styles .sidebar-menu #mapbox-geocoder-info,
#urbanform-styles .sidebar-menu #mapbox-geocoder-search {
  width: 100%;
}
#urbanform-styles .sidebar-menu #mapbox-geocoder-home .mapboxgl-ctrl-geocoder,
#urbanform-styles .sidebar-menu #mapbox-geocoder-info .mapboxgl-ctrl-geocoder,
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-search
  .mapboxgl-ctrl-geocoder {
  max-width: 100% !important;
}
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-home
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--icon,
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-info
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--icon,
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-search
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--icon {
  height: 25px;
  top: 8px;
  visibility: hidden !important;
}
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-home
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--input,
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-info
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--input,
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-search
  .mapboxgl-ctrl-geocoder
  .mapboxgl-ctrl-geocoder--input {
  height: 55px !important;
  font-weight: bold;
}
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-info
  .mapboxgl-ctrl-geocoder--input {
  height: 55px !important;
  font-weight: bold;
}
#urbanform-styles .sidebar-menu .mapboxgl-ctrl-geocoder--icon {
  visibility: hidden !important;
}
#urbanform-styles
  .sidebar-menu
  #mapbox-geocoder-search-bar
  .mapboxgl-ctrl-geocoder--input {
  font-weight: bold;
}
#urbanform-styles .sidebar-menu .search-bar {
  height: inherit;
  justify-content: center;
  background-image: url("../images/homeBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: -66px -10px -2px;
}
#urbanform-styles .sidebar-menu .info-containe {
  height: inherit;
}
#urbanform-styles .sidebar-menu .home-content {
  height: inherit;
  justify-content: center;
  background-image: url("../images/homeBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: -20px -10px -25px -10px;
}
#urbanform-styles .sidebar-menu .home-content .general-info {
  padding-top: 54px;
}
#urbanform-styles .sidebar-menu .home-content .general-info .logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
#urbanform-styles .sidebar-menu .home-content .general-info .logo-container h2 {
  margin: 0;
  font-size: 30px;
  font-weight: bolder !important;
}
#urbanform-styles
  .sidebar-menu
  .home-content
  .general-info
  .logo-container
  img {
  width: 40%;
  height: auto;
}
#urbanform-styles .sidebar-menu .home-content .general-info h4 {
  margin-top: 15px;
}
#urbanform-styles .sidebar-menu .home-content .general-info p {
  color: #fff;
  font-size: 24px;
}
#urbanform-styles .sidebar-menu .home-content .location-info {
  margin-top: 20px;
}
#urbanform-styles
  .sidebar-menu
  .home-content
  .other-info
  .zoning-info
  .bg-content {
  position: absolute;
  top: 57%;
  padding: 0 22px;
}
#urbanform-styles
  .sidebar-menu
  .home-content
  .other-info
  .zoning-info
  .bg-content
  p {
  text-align: center;
}
#urbanform-styles .sidebar-menu .home-content .other-info .zoning-info h3 {
  margin: 15px 0;
  margin-bottom: 5px;
}
#urbanform-styles .sidebar-menu .home-content .other-info .zoning-info h4 {
  margin-top: 0;
  margin-bottom: 8px;
}
#urbanform-styles .sidebar-menu .home-content .other-info .zoning-info span {
  display: block;
  font-size: 12.5px;
  color: #3699db;
}
#urbanform-styles .sidebar-menu .home-content .other-info .disclaimer-info h4 {
  margin-bottom: 8px;
}
#urbanform-styles
  .sidebar-menu
  .home-content
  .other-info
  .disclaimer-info
  .extra-info
  span {
  display: block;
  font-size: 12.5px;
  color: #3699db;
}
#urbanform-styles
  .sidebar-menu
  .home-content
  .other-info
  .disclaimer-info
  .extra-info
  span
  a {
  color: #3699db;
}
#urbanform-styles .sidebar-menu .search-content .rc-slider-disabled {
  background: none;
}
#urbanform-styles .sidebar-menu .search-content .rc-slider-mark-text,
#urbanform-styles .sidebar-menu .search-content .rc-slider-mark-text-active {
  color: #eee !important;
  font-size: 11px;
}
#urbanform-styles .sidebar-menu .search-content .zone-information-search {
  margin-top: 15px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .zone-information-search
  .dropdown-search {
  width: 100%;
  margin: 10px 0;
  min-height: 40px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .zone-information-search
  .dropdown-search
  .divider {
  font-size: 15px;
  color: #898b8a;
  margin: 0.45238095em 0 0.45238095em 0.64285714em;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .height-limit-container
  .bonus-checkbox-container,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .far-lot-container
  .bonus-checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .height-limit-container
  .bonus-checkbox-container
  .checkbox,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .far-lot-container
  .bonus-checkbox-container
  .checkbox {
  margin-bottom: 3px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .height-limit-container
  .bonus-checkbox-container
  .checkbox
  label,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .far-lot-container
  .bonus-checkbox-container
  .checkbox
  label {
  padding-left: 1.6rem;
  font-size: 13px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .height-limit-container
  .bonus-checkbox-container
  .checkbox
  label::before,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .far-lot-container
  .bonus-checkbox-container
  .checkbox
  label::before {
  width: 15px;
  height: 15px;
  left: 2px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .htlim-lot-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .htlim-bonus-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-min-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-max-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-lot-area-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-area-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-delt-area-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .far-lot-range {
  margin-bottom: 40px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-max-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-ftpt-max-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-res-range,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .far-lot-bonus-range {
  margin-bottom: 40px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-lot-area-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-ftpt-max-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-area-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-res-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-delt-area-range
  .rc-slider-mark-text:nth-last-child(odd) {
  left: 96% !important;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-lot-area-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-ftpt-max-range
  .rc-slider-mark-text:nth-last-child(odd) {
  left: 91% !important;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-lot-area-range
  .rc-slider-mark-text:nth-last-child(odd)::before,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-ground-ftpt-max-range
  .rc-slider-mark-text:nth-last-child(odd)::before {
  content: "+ SF";
  position: absolute;
  width: 100%;
  right: -85%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .htlim-lot-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .htlim-bonus-range
  .rc-slider-mark-text:nth-last-child(odd) {
  position: relative;
  left: 93% !important;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .htlim-lot-range
  .rc-slider-mark-text:nth-last-child(odd)::before,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .htlim-bonus-range
  .rc-slider-mark-text:nth-last-child(odd)::before {
  content: "+ FT";
  position: absolute;
  width: 115%;
  right: -130%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-min-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-max-range
  .rc-slider-mark-text:nth-last-child(odd) {
  position: relative;
  left: 96.5% !important;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-min-range
  .rc-slider-mark-text:nth-last-child(odd)::before,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .unit-lot-max-range
  .rc-slider-mark-text:nth-last-child(odd)::before {
  content: "+";
  position: absolute;
  width: 50px;
  right: -160%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-area-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-res-range
  .rc-slider-mark-text:nth-last-child(odd),
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-delt-area-range
  .rc-slider-mark-text:nth-last-child(odd) {
  position: relative;
  left: 89.5% !important;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-area-range
  .rc-slider-mark-text:nth-last-child(odd)::before,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-max-res-range
  .rc-slider-mark-text:nth-last-child(odd)::before,
#urbanform-styles
  .sidebar-menu
  .search-content
  .density-regulations-search
  .envp-building-delt-area-range
  .rc-slider-mark-text:nth-last-child(odd)::before {
  content: "+ SF";
  position: absolute;
  width: 30px;
  right: -58%;
}
#urbanform-styles .sidebar-menu .search-content .search-boxes h3 {
  margin-bottom: 5px;
}
#urbanform-styles .sidebar-menu .search-content .search-boxes .search-box {
  margin-bottom: 10px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-boxes
  .search-box
  input {
  padding-bottom: 14px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-boxes
  .search-box
  input::placeholder {
  font-size: 15px;
  color: #898b8a;
}
#urbanform-styles .sidebar-menu .search-content .slider-filter {
  margin: 15px 5px;
  margin-top: 7px;
}
#urbanform-styles .sidebar-menu .search-content h3 {
  font-size: 18.5px;
}
#urbanform-styles .sidebar-menu .search-content h4 {
  font-size: 14.5px;
  color: #abe2fb;
  margin-bottom: 5px;
}
#urbanform-styles .sidebar-menu .search-content h5 {
  font-size: 12px;
  margin-left: -5px;
  margin-top: 7px;
  margin-bottom: 10px;
  text-transform: none;
}
#urbanform-styles .sidebar-menu .search-content .reset-button,
#urbanform-styles .sidebar-menu .search-content .search-button {
  width: 100%;
  padding: 15px !important;
  margin-top: 5px;
}
#urbanform-styles .sidebar-menu .search-content .search-input {
  width: 100%;
  margin: 5px 0;
  max-height: 38px;
}
#urbanform-styles .sidebar-menu .search-content .search-input .button {
  cursor: default;
  background: #3699db !important;
  padding: 5px !important;
  border: none !important;
  width: 40%;
  min-height: 38px;
  font-size: 14px;
  line-height: 16px;
  justify-content: center;
}
#urbanform-styles .sidebar-menu .search-content .search-input input {
  border: none;
  width: 60%;
}
#urbanform-styles .sidebar-menu .search-content .search-result-container {
  margin-top: 25px;
  box-shadow: none;
  padding-top: 0;
  transition: 0.3s ease-in-out all;
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .ui.divided.list {
  margin-bottom: 0;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .spinner-container {
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .spinner-container
  .spinner {
  display: block;
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item
  .content {
  padding: 3px 10px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item
  .content
  .header {
  font-size: 15px;
  margin-bottom: 2px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item
  .content
  .description {
  font-size: 13px;
  opacity: 0.8;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item:first-child {
  padding-top: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item:last-child {
  padding-bottom: 10px;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item:hover {
  background: #537baf;
  color: #fff;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item:hover
  .header,
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-item:hover
  .description {
  color: #fff;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .pagination-container {
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .pagination-container
  .pagination {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .search-result-count {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 10px;
  text-transform: capitalize;
}
#urbanform-styles
  .sidebar-menu
  .search-content
  .search-result-container
  .no-search-result-container {
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
#urbanform-styles .sidebar-menu .user-content .user-login {
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
}
#urbanform-styles .sidebar-menu .user-content .user-login .login-form {
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .login-fields-container {
  width: 100%;
}
#urbanform-styles .sidebar-menu .user-content .user-login .grayed-text {
  color: #818286;
  font-size: x-large;
  text-align: center;
  margin: 18px 0;
}
#urbanform-styles .sidebar-menu .user-content .user-login .ui.input > input {
  border-radius: 10px;
}
#urbanform-styles .sidebar-menu .user-content .user-login .input {
  margin: 5px 0;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .input
  input::placeholder {
  font-size: 18px;
}
#urbanform-styles .sidebar-menu .user-content .user-login .login-error-message {
  padding: 1em !important;
}
#urbanform-styles .sidebar-menu .user-content .user-login .options {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
#urbanform-styles .sidebar-menu .user-content .user-login .options span {
  display: block;
  width: 100%;
  text-align: right;
}
#urbanform-styles .sidebar-menu .user-content .user-login .options span a {
  font-size: 13px;
  color: #3da9f1;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .subscription-container {
  margin-top: 25px;
}
#urbanform-styles .sidebar-menu .user-content .user-login .actions-container {
  width: 100%;
  margin-top: 16px;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .actions-container
  .signup-styles {
  background-color: #fddb6e !important;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .actions-container
  .button {
  color: #000;
  font-size: 18px;
  padding: 20px 20px;
  background: #f8d9e6;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .actions-container
  .button:hover {
  background: #43c1f3 !important;
}
#urbanform-styles
  .sidebar-menu
  .user-content
  .user-login
  .actions-container
  .button
  a {
  color: #fff;
  text-decoration: none;
}
@media only screen and (max-width: 425px) {
  #urbanform-styles .sidebar-menu .user-content .user-login .grayed-text {
    font-size: medium;
  }
  #urbanform-styles
    .sidebar-menu
    .user-content
    .user-login
    .actions-container
    .button {
    font-size: 14px;
  }
  #urbanform-styles
    .sidebar-menu
    .user-content
    .user-login
    .input
    input::placeholder {
    font-size: 14px;
  }
}
#urbanform-styles .sidebar-menu .mapboxgl-ctrl-geocoder {
  max-width: 100% !important;
  width: 100% !important;
}
#urbanform-styles .sidebar-menu .search-box {
  height: 40px;
}
#urbanform-styles .sidebar-menu .input input::placeholder {
  color: darkgrey;
}
#urbanform-styles .sidebar-menu .input input:focus {
  border: 1px solid #26374d;
}
#urbanform-styles .sidebar-menu .input input:focus::placeholder {
  color: #26374d;
}
#urbanform-styles .sidebar-menu .table {
  margin-bottom: 5px;
  border: none;
}
#urbanform-styles .sidebar-menu .table th {
  background: #eeeeee;
  text-transform: uppercase;
}
#urbanform-styles .sidebar-menu .table td b {
  text-transform: uppercase;
  font-size: 13px;
}
#urbanform-styles .sidebar-menu .divider {
  margin: 20px 0;
}
#urbanform-styles .sidebar-menu .new-divider {
  margin: 10px 0;
}
#urbanform-styles .sidebar-menu .sidebar-table .sub-category-container {
  background: #ebf2fa;
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .sidebar-table
  .sub-category-container
  .sub-category {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: #26374d;
  text-transform: uppercase;
  font-weight: 700;
}
#urbanform-styles
  .sidebar-menu
  .sidebar-table
  .sub-category-container
  .sub-category
  span {
  font-size: 20px;
}
#urbanform-styles .sidebar-menu .accordion-table {
  color: #000;
  background: #fff;
  border-radius: 5px;
}
#urbanform-styles .sidebar-menu .accordion-table .title {
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  background: #eee;
  padding: 12px 10px;
  border-radius: 5px;
}
#urbanform-styles .sidebar-menu .accordion-table .no-bottom-border {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#urbanform-styles .sidebar-menu .accordion-table .content {
  padding: 0;
}
#urbanform-styles .sidebar-menu .accordion-table .content tbody {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
#urbanform-styles .sidebar-menu .accordion-table .content tbody tr {
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
  border-bottom: 1px solid #eee;
  text-transform: uppercase;
}
#urbanform-styles .sidebar-menu .accordion-table .content tbody tr:last-child {
  border-bottom: none;
}
#urbanform-styles .sidebar-menu .accordion-table .content tbody .table-row {
  min-height: 45px;
  align-items: center;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .content
  tbody
  .table-row
  .table-title {
  text-align: left;
  margin-right: 5px;
  font-size: 13px;
  width: 100%;
  max-width: 78%;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .content
  tbody
  .table-row
  .table-content {
  text-align: right;
  margin-left: 5px;
  min-width: 20%;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .content
  tbody
  .table-row
  .table-content
  .pro-label {
  background: #26374d;
  color: #ebf2fa;
  padding: 6px 9px;
  border-radius: 5px;
  font-size: 11.5px;
  font-weight: 700;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .content
  tbody
  .table-row
  .table-content
  .normal-content {
  color: "#26374d";
  font-weight: 700;
  text-transform: none !important;
}
#urbanform-styles .sidebar-menu .accordion-table .sub-category-container {
  background: #ebf2fa;
  width: 100%;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .sub-category-container
  .sub-category {
  padding: 5px 10px;
  display: block;
  color: #26374d;
  text-transform: uppercase;
  font-weight: 700;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .sub-category-container
  .sub-category
  span {
  font-size: 20px;
}
#urbanform-styles .sidebar-menu .accordion-table .use-regulations-details {
  padding: 7px 10px;
  color: #26374d;
  background: #26374d;
  background: #cce2ff;
  font-weight: 600;
  font-size: 13px;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .use-regulations-details
  .title-words {
  color: #26374d;
  font-weight: 900;
  background: #26374d;
  padding: 2px 5px;
  border-radius: 15px;
  color: #fff;
}
#urbanform-styles
  .sidebar-menu
  .accordion-table
  .use-regulations-details
  .section-divider {
  margin: 0 3px;
}
#urbanform-styles .sidebar-menu .back {
  width: 55px;
  margin-left: 16px;
  margin-bottom: 10px;
}

@media screen and (max-width: 320px) {
  #urbanform-styles .sidebar-menu .home-content .bg-content {
    padding: 0 15px !important;
  }
}
@media screen and (max-height: 600px) {
  #urbanform-styles
    .menu-columns
    > div.thin-column
    .menu
    .icon-items
    .icon-item {
    padding: 0 0 0 !important;
  }
  #urbanform-styles
    .menu-columns
    > div.thin-column
    .menu
    .icon-items
    .icon-item.active {
    padding: 7px !important;
  }
}
@media screen and (max-height: 500px) {
  #urbanform-styles
    .menu-columns
    > div.thin-column
    .menu
    .icon-items
    .icon-item
    .image {
    height: 20px !important;
  }
  #urbanform-styles
    .menu-columns
    > div.thin-column
    .menu
    .icon-items
    .icon-item
    span {
    font-size: 10px !important;
  }
  #urbanform-styles .menu-columns > div.thin-column .menu .icon-logo span {
    font-size: 10px !important;
  }
  #urbanform-styles .menu-columns > div.thin-column .menu .icon-logo img {
    width: 40px !important;
    height: 40px !important;
  }
  #urbanform-styles .menu-columns > div.thin-column .menu .icon-items {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 326px), screen and (max-height: 550px) {
  #urbanform-styles
    .sidebar-menu
    .home-content
    .other-info
    .zoning-info
    .bg-content {
    top: 60% !important;
  }
}
