.result-section .result-count {
  border-top: 1px solid #f2f2f2;
  margin: 20px auto 20px auto;
  padding: 20px;
  color: #000;
}
.result-section .result-count h1 {
  font-weight: bolder;
  font-size: 50px;
}
.result-section .result-body {
  margin: 20px 0;
  padding: 5px;
  border-top: 1px solid #f2f2f2;
}
.result-section .site-address {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 21px;
  opacity: 0.8;
}
.result-section .site-info {
  margin-bottom: 5px;
}
.result-section .table {
  padding: 0 3px;
}
.result-section .table .table-row:hover {
  background: #eff1f2;
  cursor: pointer;
}
.result-section .table tr td {
  padding: 10px !important;
}
.result-section .pagination {
  border: none;
  box-shadow: none;
}
.result-section .pagination .item {
  position: static !important;
  padding: 11px;
}
.result-section .pagination .active.item {
  background-color: #fff !important;
  font-weight: bolder;
  padding: 11px;
}
