.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}

.zone-detail .overlays_a li {
  font-weight: normal;
}

.zone_detail_c .heading-text {
  width: 235px;
}
