@charset "UTF-8";
.segment-header {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 1px;
}
.segment-header .header-display {
  margin-left: 30px;
  margin-top: 12px;
  padding: 10px 0;
}
.segment-header .header-display .segment-text {
  color: #000;
  font-weight: bolder;
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.7;
  font-family: "Inter-Bold";
}

.span-styles {
  font-weight: normal;
  color: #818286;
  font-size: 16px;
}

.section .section-display-cities {
  margin-left: 30px !important;
}
.section .section-display-cities .total-price-section {
  margin-top: 18px;
}
.section .section-display-cities .total-price-section .purchase {
  color: #57585a !important;
}
.section .section-display-cities .total-price-section .price-section {
  margin-left: 35px;
}
.section .section-display-cities .total-price-section .price-section .price {
  margin-top: 12px;
  margin-bottom: 12px;
}
.section .section-display-cities .total-price-section .price-section .total {
  color: #57595a !important;
}
.section .section-display-cities .total-price-section .black-plus {
  color: black;
}
.section .green-check {
  color: green;
}
.section .yellow-check {
  color: #fddb6e;
}
.section .section-display {
  margin-top: 10px;
  padding-bottom: 10px;
  margin-left: 30px;
}
.section .section-display .per-unit {
  margin-bottom: 0 !important;
}
.section .section-display .warning {
  color: #000;
  padding: 16px;
  background: #fddb6e;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  border-color: transparent;
  margin: 18px 24px 18px -20px;
  width: 90% !important;
  font-size: 18px;
}
.section .section-display .warning:hover {
  background: #43c1f3;
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}
.section .section-display .primary {
  color: #000 !important;
  padding: 16px;
  background: #43c1f3;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
  border-color: transparent;
  width: 90% !important;
  font-size: 18px;
}
.section .section-display .primary:hover {
  background: #fddb6e !important;
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}
.section .section-display .plan-headers {
  display: flex;
  justify-content: space-around;
}
.section .section-display .plan-headers span {
  font-weight: normal;
  color: #818286;
  font-size: 16px;
}
.section .section-display .plan-content {
  display: flex;
}
.section .section-display .plan-content .custom-checkboxes label::after {
  content: " •";
  font-weight: bolder;
  font-size: 20px;
  margin-top: -1px;
}
.section .section-display .plan-content .ui.checkbox input:checked ~ .box:after,
.section
  .section-display
  .plan-content
  .ui.checkbox
  input:checked
  ~ label:after {
  color: white;
}
.section
  .section-display
  .plan-content
  .ui.checkbox
  input:checked
  ~ .box:before,
.section
  .section-display
  .plan-content
  .ui.checkbox
  input:checked
  ~ label:before {
  background: #fddb6e;
  border-radius: 9px;
}
.section .section-display .plan-content .ui.disabled.checkbox label:before {
  border: 3px solid #d4d4d5;
}
.section .section-display .plan-content .ui.checkbox label:before {
  border: 3px solid #636363;
}
.section .section-display .plan-content .ui.checked.checkbox label:before {
  border: none !important;
}
.section .section-display .plan-content .ui.checkbox input.hidden + label {
  opacity: 1 !important;
}
.section .section-display .plan-content .ui.checkbox .box:before,
.section .section-display .plan-content .ui.checkbox label:before {
  border-radius: 0;
}
.section .bottom-margin {
  margin-bottom: 36px !important;
}

.data-footer {
  background: #fffbf1;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  padding-bottom: 18px;
}

.plan-body p {
  color: black;
  font-size: 20px;
  margin-bottom: 10px !important;
  text-transform: capitalize;
}
.plan-body span {
  font-weight: bold;
  color: black;
  margin-left: 3px;
  font-size: 20px;
  opacity: 0.7;
}
