.summary .section .section-data {
  padding-bottom: 0px;
}

.lock .lock-image {
  margin-left: 30px;
  margin-top: -128px;
  margin-bottom: 28px;
}

.lock .sub-heading-lock {
  margin-top: 12px !important;
  margin-left: 68px;
  font-size: 18px !important;
  margin-bottom: -7px;
  color: #818286;
  padding-right: 16px;
}

.summary .plan_districts_a li {
  font-weight: normal;
  font-size: 17px;
  width: 151px;
}

.summary .plan_districts_b li {
  font-weight: normal;
  font-size: 17px;
}

.summary .plan_districts_c li {
  font-weight: normal;
  font-size: 17px;
}

.image-box {
  margin: 24px 4px 16px 3px;
}
.image-box img {
  height: 295px;
  border-radius: 11px;
}

.zone_a .sub-heading {
  font-size: 10px;
  margin-bottom: 0px !important;
}

.zone_b .sub-heading {
  width: 183px;
}

.setbacks_e .heading-text {
  width: 200px;
}

.summary .summary-footer {
  margin-left: -29px;
}
