.section {
  border-top: 1px solid #f2f2f2;
  padding-top: 15px;
  margin-right: 10px;
}
.section h2 {
  color: black;
  font-weight: bolder;
}
.section p {
  font-weight: normal;
  color: black;
  font-size: 18px;
}
.section .sub-title {
  margin-left: 63px;
  color: black;
}

.property-main-more {
  height: 10px;
}

.setbacks_f {
  margin-top: -5px;
}

.coverage_land_detail_d {
  margin-bottom: 12px;
}

.land-detail .setback-hr {
  margin-top: -18px;
}

.land-detail .setbacks_g .section-description {
  padding-bottom: 15px;
}

.land-detail .setbacks_h {
  margin-bottom: 18px;
  margin-top: 15px;
}

.setbacks_g {
  width: 231px;
}

.land-detail .setbacks_h .section-description {
  display: flex;
}

.max_foot_print_a {
  margin-right: 18px;
}

.far_bonus_l {
  margin-right: 18px;
}
