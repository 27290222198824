.data-header .container-styles {
  height: 364px;
}
.data-header .height-limit-container .height-limit-field-div {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  height: 227px;
  margin-top: 18px;
}
.data-header .height-limit-container .height-limit-field-div .min-div {
  margin-top: 17px;
  margin-left: -122px;
}
.data-header .height-limit-container .height-limit-field-div .horizontal-row {
  margin-left: 27px;
}
.data-header
  .height-limit-container
  .height-limit-field-div
  .height-limit-checkbox {
  margin-left: 74px;
  margin-top: 59px;
}
.data-header
  .height-limit-container
  .height-limit-field-div
  .height-limit-checkbox-label {
  margin-left: 13px;
  font-size: 15px;
}
.data-header
  .height-limit-container
  .height-limit-field-div
  .height-input-label {
  margin-left: 57px;
}
