.contact-city .portland-seal {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.contact-city .portland-seal img {
  width: 96%;
  margin-right: 7px;
}
.contact-city .contact-city-title h1 {
  color: #000000;
}
.contact-city .contact-city-title p,
.contact-city .contact-city-title a {
  font-size: 18px;
  color: #000000;
  opacity: 0.6;
}
.contact-city .contact-city-title a {
  opacity: 1 !important;
}

.contact-city {
  margin: 18px;
}

.news-feed {
  background-color: white;
  border-radius: 12px;
}
.news-feed .news-header {
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
}
.news-feed .news-header h2 {
  color: black;
  margin-left: 30px;
  line-height: 24px;
  text-overflow: ellipsis;
  width: 243px;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Inter-Bold";
  opacity: 0.7;
  font-size: 22px;
}
.news-feed .news-body {
  margin: 10px 30px 10px 30px;
  padding-bottom: 10px;
}
.news-feed .news-body p {
  color: black;
  padding: 10px 0;
}

.help-jury h1 {
  color: #4f4f4f;
  font-size: 40px;
  font-weight: bold;
  padding: 10px;
  font-family: "Inter-Black";
}
.help-jury .jury-card {
  background-color: white;
  border-radius: 12px;
}
.help-jury .jury-card .jury-header {
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
}
.help-jury .jury-card .jury-header h3 {
  color: black;
  margin-left: 30px;
  line-height: 24px;
  text-overflow: ellipsis;
  width: 243px;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Inter-Bold";
  opacity: 0.7;
  font-size: 22px;
}
.help-jury .jury-card .jury-body {
  margin: 10px 30px 10px 30px;
  padding-bottom: 10px;
}
.help-jury .jury-card .jury-body .jury-info {
  color: #4f4f4f;
  padding: 10px 0;
  font-size: 17px;
  line-height: 22px;
}
.help-jury .jury-card .jury-body .juris-contact {
  color: black;
  opacity: 0.7;
  font-size: 17px;
  line-height: 22px;
}
.help-jury .jury-card .jury-body .juris-contact span {
  font-weight: bold !important;
  opacity: unset !important;
}
.help-jury .jury-card .jury-body .juris-link {
  color: #69beee !important;
  font-size: 17px;
}
.help-jury .jury-card .jury-body .juris-link a {
  color: #69beee !important;
}

.help-zone h1 {
  color: #4f4f4f;
  font-size: 40px;
  font-weight: bold;
  padding: 10px;
  font-family: "Inter-Black";
}
.help-zone .zone-card {
  background-color: white;
  border-radius: 12px;
}
.help-zone .zone-card .zone-header {
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0;
}
.help-zone .zone-card .zone-header h3 {
  color: black;
  margin-left: 30px;
  line-height: 24px;
  text-overflow: ellipsis;
  width: 243px;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Inter-Bold";
  opacity: 0.7;
  font-size: 22px;
}
.help-zone .zone-card .zone-body {
  margin: 10px 30px 10px 30px;
  padding-bottom: 10px;
}
.help-zone .zone-card .zone-body p {
  color: black;
  padding: 10px 0;
}
.help-zone .zone-card .zone-body a {
  padding-bottom: 5px;
  color: #69beee;
}

@media only screen and (max-width: 500px) {
  .news-feed .news-header h2 {
    font-size: 18px;
    width: 223px;
  }
}
