.testing.checkbox .box:before,
.testing.checkbox label:before {
  border: 1px solid black !important;
  width: 18px !important;
  height: 18px !important;
}

.testing.radio.checkbox input:checked ~ .box:after,
.testing.radio.checkbox input:checked ~ label:after {
  background-color: yellow !important;
  border: 2px solid black !important;
  width: 18px !important;
  height: 18px !important;
}

.spacing {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top-bottom-rule {
  margin: 18px 0;
}

.slider > p {
  margin-bottom: 12px;
}
