.slider .color {
  color: black;
}
.slider .column {
  margin-top: 10px;
}
.slider .fields {
  width: 116px;
  border-radius: 14px !important;
  text-align: right !important;
  border: 1px solid rgba(34, 36, 38, 0.35);
}
.slider .input-label {
  float: right;
}
.slider .range-grid {
  margin-left: 61px;
  margin-top: -24px;
  width: 223px;
}
.slider .slider-checkbox {
  margin-left: 55px;
}
.slider .height-limit-checkbox {
  margin-left: 74px;
  margin-top: 59px;
  display: flex;
}
.slider .height-limit-checkbox-label {
  margin-left: 8px;
  font-size: 16px;
  display: block;
}
.slider .checkbox-grid {
  margin-left: 59px;
}
.slider .checkbox-section {
  margin-left: 47px;
}
.slider .horizontal-row {
  width: 50%;
}

.semantic_ui_range_inner div:nth-child(3) {
  position: absolute;
  top: 0px;
  left: -10px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 5px solid rgb(0, 212, 255);
  box-shadow:
    0px 1px 2px 0px,
    0px 0px 0px 1px;
}

.semantic_ui_range_inner div:nth-child(4) {
  border: 5px solid rgb(255, 0, 135);
  position: absolute;
  top: 0px;
  left: 165px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: white;
}

.ui.input.focus > input,
.ui.input > input:focus {
  border-color: #000;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: none;
  opacity: 0.5;
}

.inputRow {
  display: flex;
  align-items: flex-end;
}
.inputRow input {
  padding: 5px !important;
  border: 1px solid rgba(34, 36, 38, 0.35);
  width: 116px !important;
  border-radius: 14px !important;
  text-align: right !important;
}
