.zone-filter {
  color: #000;
  margin-bottom: 10px;
}
.zone-filter .accordion {
  box-shadow: unset !important;
  border-radius: 8px !important;
}
.zone-filter .accordion .title {
  padding: unset !important;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.zone-filter .accordion .title i {
  position: absolute;
  right: 0;
  top: 15px;
  font-size: 20px;
}
.zone-filter .accordion .content {
  padding: 0px !important;
}
.zone-filter .zone-sub-title {
  margin-left: 58px;
}
.zone-filter .zone-fields {
  border-top: 1px solid #f2f2f2;
  padding: 17px 20px 26px 42px;
  margin-bottom: 16px;
}
.zone-filter .horizontal-row {
  margin-top: 25px;
  margin-bottom: 25px;
}
