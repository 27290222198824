.info-container {
  margin-top: 30px;
}
.info-container .info-heading {
  padding: 16px 0px;
  margin-bottom: 16px;
}
.info-container .info-heading h1 {
  font-weight: bolder;
  font-size: 40px;
  margin-bottom: 0px;
}
.info-container .info-heading .heading-button {
  width: 310px;
  text-align: left;
  background-color: white;
}

.info-item:has(button.disabled) {
  cursor: not-allowed !important;
}

.underlays-switcher:has(div div input:disabled) {
  cursor: not-allowed !important;
}

.info-container .info-item {
  margin-top: 10px;
}
.info-container .info-item .info-controls-btns .image {
  margin: auto 0;
}
.info-container .info-item .info-controls-btns .info-icon {
  width: 35px !important;
}
.info-container .info-item .info-controls-btns span {
  font-size: 14px;
  display: inline-block;
  margin: auto 0px auto 11px;
  color: #69beee;
  text-transform: uppercase;
  text-align: justify;
  line-height: 20px;
  font-weight: 400;
  font-family: "Inter-Regular";
}
.info-container .info-item .info-controls-btns .centered {
  width: 100%;
  opacity: 1;
}
.info-container .info-item .button {
  display: flex !important;
  background: rgba(255, 255, 255, 0.35) !important;
  border-radius: 12px !important;
  padding: 10px 15px !important;
  height: 55px;
}
.info-container .info-item .active,
.info-container .info-item:hover {
  background: white !important;
}
.info-container .info-item .active span,
.info-container .info-item:hover span {
  font-weight: 700;
}
.info-container .see-result-btn {
  position: absolute;
  margin-top: 140px;
  right: 10px;
  left: 10px;
}
.info-container .see-result-btn .result-button.ui.button {
  display: flex;
  border-radius: 12px !important;
  padding: 10px 15px !important;
  height: 60px;
  background: #ffd600;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  transition: 0.3s ease-in-out all;
}
.info-container .see-result-btn .result-button.ui.button span {
  font-size: 12px;
  display: inline-block;
  margin: auto 20px;
  text-transform: uppercase;
  text-align: justify;
  font-weight: bold;
  font-family: "Inter-Regular";
}
.info-container .see-result-btn .result-button.ui.button .image {
  width: 40px;
  margin: auto 0;
}
.info-container .see-result-btn .result-button.ui.button:hover {
  background: #43c1f3;
}
.info-container .see-result-icons {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  margin-top: -29px;
}
.info-container .rotate {
  padding: 6px;
  background: white;
  border-radius: 21px;
  margin: 4px;
}
.info-container .file {
  padding: 6px;
  background: white;
  border-radius: 12px;
  margin: 4px;
  width: 95px;
  height: 50px;
}
.info-container .heart {
  padding: 6px;
  background: white;
  border-radius: 12px;
  margin: 4px;
  cursor: pointer;
  width: 95px;
  height: 50px;
}
.info-container .image-size {
  margin: 0 auto;
  margin-top: 6px;
}
.info-container img:hover {
  cursor: pointer;
}
.info-container .ui.input {
  width: 100%;
}
.info-container .info-reports {
  border: none;
  margin-top: 5px;
  margin-bottom: 10px;
}
.info-container .info-reports p {
  width: 100%;
  text-align: center;
  color: #69beee;
  margin-top: 35px;
}
.info-container .info-reports .package {
  margin-top: -17px;
}
.info-container .info-reports .upgrade-package {
  padding: 3px;
  text-align: center;
  color: black;
  opacity: 0.6 !important;
  cursor: pointer;
}
.info-container .download-btn-active {
  position: absolute;
  right: 0px;
  left: 5px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  margin: 130px 0 10px -10px;
  border-top-right-radius: 10px;
}
