.sign-up {
  color: #000;
  padding: 5px 20px 20px;
}
.sign-up h1 {
  font-family: "Inter-Black";
}
.sign-up .signup-form {
  background: #ffffff;
  border-radius: 10px;
}
.sign-up .signup-form h3 {
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 15px 7px;
  font-family: "Inter-Bold";
}
.sign-up .signup-form .form-body {
  padding: 10px 10px 7px;
}
.sign-up .signup-header {
  display: flex;
  height: 50px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
}
.sign-up .signup-header .signup-icon-contact {
  height: 30px;
  margin: auto 15px;
}
.sign-up .signup-header h4 {
  margin: auto 0;
  font-size: x-large;
  color: #818286;
  margin-left: 16px;
}
.sign-up .ui.form .field {
  margin: 0 0 0.5em;
}
.sign-up .ui.form select {
  border: none;
}
.sign-up .ui.input.transparent {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
}
.sign-up .ui.form .field > label {
  color: #333333 !important;
  padding-left: 10px;
  margin-top: 16px;
  font-weight: 500;
}
.sign-up .ui.form .field.error .input {
  border: 1px solid #f2f2f2;
}
.sign-up .ui.form .field.field input:-webkit-autofill {
  box-shadow: 0 0 0 100px white inset !important;
  border-color: #fffffa !important;
}
.sign-up .ui.form .field.field input:-webkit-autofill :focus {
  box-shadow: 0 0 0 100px white inset !important;
  border-color: #fffffa !important;
}
.sign-up .update-account-btn {
  margin-top: 10px;
}
.sign-up .update-account-btn .button {
  display: flex;
  color: #000;
  font-size: 18px;
  padding: 16px 25px;
  background: #ffd600;
  border-radius: 12px;
  transition: 0.3s ease-in-out all;
}
.sign-up .update-account-btn .button span {
  margin-left: 10px;
  margin-top: 5px;
  text-transform: uppercase;
}
.sign-up .update-account-btn .button:hover {
  background: #43c1f3;
}
.sign-up .update-account-btn .button a {
  color: #fff;
  text-decoration: none;
}
.sign-up .active {
  background: #fff !important;
  font-weight: normal !important;
}
.sign-up .errorMessage {
  font-size: 12px;
  color: #ff0000;
}

.policy-text p {
  background-color: white;
  margin-top: 10px !important;
  padding: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 425px) {
  .sign-up .signup-form h3 {
    font-size: 13px;
  }
  .sign-up .signup-header h4 {
    margin-left: -1px;
  }
}
