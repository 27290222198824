.report-purchase .btn {
  margin: 30px;
}
.report-purchase .btn .primary-button.ui.button {
  background: #f8d9e6;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.report-purchase .btn .primary-button.ui.button:hover {
  background: #43c1f3;
}
.report-purchase .gold-button.ui.button {
  background: #fddb6e;
  line-height: 18px;
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  padding: 20px;
  border-radius: 25px;
  transition: 0.3s ease-in-out all;
}
.report-purchase .gold-button.ui.button:hover {
  background: #43c1f3;
}
